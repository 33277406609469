<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <b-card title="Quality Control - Monitoring">
          <b-row>
            <b-col cols="4" >
              <b-form-group label="Ruleset" label-size="sm" label-for="select-station" size="sm">
                <v-select v-model="RESOURCE" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="LABEL"
                  :options="RESOURCE_STORE" class="select-size-sm" />
              </b-form-group>
            </b-col>

            <b-col cols="4" >
              <b-form-group label="Tanggal" label-size="sm" label-for="select-station" size="sm" style="">
                <a-date-picker @change="onChange" :disabledDate="disabledDate" class="w-100 h-100"/>
              </b-form-group>
            </b-col>

            <b-col sm="4 mt-2 pad2" xl="1" md="2">
              <b-form-group>
                <b-form inline id="select-form">
                  <b-button v-on:click="onFetch" variant="primary" class="ml-sm-6" size="md" style="margin-top: 2px;">Filter</b-button>
                </b-form>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>
    <a-spin :spinning="isLoading">
      <div class="row">
        <div class="col-xl-12">
          <b-card>
            <vue-good-table 
              :columns="columns" 
              :rows="rows" 
              :select-options="{ enabled: false }"
              :sort-options="{
                enabled: false,
              }"
              @on-cell-click="onCellClick"
              :pagination-options="{ enabled: true }">
              <!-- <template slot="table-row" slot-scope="props">
                <div v-if="
                  props.column.renderFlag == false && props.column.field != 'progress'
                " style="display: block; text-align: center; white-space: nowrap">
                  {{ props.row[props.column.field] }}
                </div>
                <div v-if="props.column.field == 'progress'">
                  {{ props.row[props.column.field] }}%
                </div>
                <div v-if="
                  props.column.renderFlag == true && props.column.field != 'progress'
                " class="icons-visual">
                  <a-icon type="check" v-if="props.row[props.column.field] == '1'" style="color: #00ff00" />
                  <a-icon type="close" v-if="props.row[props.column.field] == '0'" style="color: #ff0000" />
                  <a-icon type="stop" v-if="props.row[props.column.field] === '-'" />
                </div>
              </template> -->

              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'station_id' || props.column.field == 'station_name'">
                  <span>
                    <span style="font-weight: bold; color: grey; font-size:12px">{{props.row[props.column.field]}}</span> 
                  </span>
                </span>

                <span v-else-if="props.column.field == '00:00' || props.column.field == '01:00' || props.column.field == '02:00' || props.column.field == '03:00' || 
                            props.column.field == '04:00' || props.column.field == '05:00' || props.column.field == '06:00' || props.column.field == '07:00' ||
                            props.column.field == '08:00' || props.column.field == '09:00' || props.column.field == '10:00' || props.column.field == '11:00' ||
                            props.column.field == '12:00' || props.column.field == '13:00' || props.column.field == '14:00' || props.column.field == '15:00' ||
                            props.column.field == '16:00' || props.column.field == '17:00' || props.column.field == '18:00' || props.column.field == '19:00' ||
                            props.column.field == '20:00' || props.column.field == '21:00' || props.column.field == '22:00' || props.column.field == '23:00' 
                ">
                  <span v-if="props.row[props.column.field]['flag'] == 'C'">
                    <span style="font-weight: bold; color: blue; font-size:16px">{{props.row[props.column.field]['flag']}}</span> 
                  </span>
                  <span v-else-if="props.row[props.column.field]['flag'] == 'S'">
                    <span class="is-green" style="font-weight: bold; color: #E38B29; font-size:16px; ">{{props.row[props.column.field]['flag']}}</span> 
                  </span>
                  <span v-else-if="props.row[props.column.field]['flag'] == 'E'">
                    <span style="font-weight: bold; color: red; font-size:16px">{{props.row[props.column.field]['flag']}}</span> 
                  </span>
                  <span v-else-if="props.row[props.column.field]['flag'] == '-'">
                    <span style="font-weight: bold; color: grey; font-size:16px"></span> 
                  </span>
                  <span v-else>
                    <span style="font-weight: bold; color: grey; font-size:16px"></span> 
                  </span>
                </span>

                <span v-else-if="props.column.field == '01' || props.column.field == '02' || props.column.field == '03' || props.column.field == '04' || 
                            props.column.field == '05' || props.column.field == '06' || props.column.field == '07' || props.column.field == '08' ||
                            props.column.field == '09' || props.column.field == '10' || props.column.field == '11' || props.column.field == '12' ||
                            props.column.field == '13' || props.column.field == '14' || props.column.field == '15' || props.column.field == '16' ||
                            props.column.field == '17' || props.column.field == '18' || props.column.field == '19' || props.column.field == '20' ||
                            props.column.field == '21' || props.column.field == '22' || props.column.field == '23' || props.column.field == '24' ||
                            props.column.field == '25' || props.column.field == '26' || props.column.field == '27' || props.column.field == '28' ||
                            props.column.field == '29' || props.column.field == '30' || props.column.field == '31' 
                ">
                  <span v-if="props.row[props.column.field]['flag'] == 'C'">
                    <span style="font-weight: bold; color: blue; font-size:16px">{{props.row[props.column.field]['flag']}}</span> 
                  </span>
                  <span v-else-if="props.row[props.column.field]['flag'] == 'S'">
                    <span class="is-green" style="font-weight: bold; color: #E38B29; font-size:16px; ">{{props.row[props.column.field]['flag']}}</span> 
                  </span>
                  <span v-else-if="props.row[props.column.field]['flag'] == 'E'">
                    <span style="font-weight: bold; color: red; font-size:16px">{{props.row[props.column.field]['flag']}}</span> 
                  </span>
                  <span v-else-if="props.row[props.column.field]['flag'] == '-'">
                    <span style="font-weight: bold; color: grey; font-size:16px"></span> 
                  </span>
                  <span v-else>
                    <span style="font-weight: bold; color: grey; font-size:16px"></span> 
                  </span>
                </span>
              </template>
            </vue-good-table>
          </b-card>
        </div>
      </div>
    </a-spin>

    <b-modal
			id="modal-qc-ev"
			ref="modal-qc-ev"
			title="QUALITY CONTROL ELEMENT VALUE"
			button-size="sm"
			size="md"
			centered
			no-close-on-backdrop
		>

      <b-row class="mb-1" >
          <b-col cols="4">
              <div class="label-entry1">
                  Element
              </div>
          </b-col>
          <b-col cols="8">
                <b-form-input
                  id="pic"
                  v-model="modal.element"
                  size="sm"
                  class="text-left"
                  disabled
                  style="background:white"
              ></b-form-input>
          </b-col>
      </b-row>
      <b-row class="mb-1">
          <b-col cols="4">
              <div class="label-entry1">
                  Station ID
              </div>
          </b-col>
          <b-col cols="8">
              <b-form-input
                  id="pic"
                  v-model="modal.station_id"
                  size="sm"
                  class="text-left"
                  disabled
                  style="background:white"
              ></b-form-input>
          </b-col>
      </b-row>
      <b-row class="mb-1">
          <b-col cols="4">
              <div class="label-entry1">
                  Timestamp
              </div>
          </b-col>
          <b-col cols="8">
              <b-form-input
                  id="pic"
                  v-model="modal.timestamp"
                  size="sm"
                  class="text-left"
                  disabled
                  style="background:white"
              ></b-form-input>
          </b-col>
      </b-row>
      <b-row class="mb-1">
          <b-col cols="4">
              <div class="label-entry1">
                  Flag
              </div>
          </b-col>
          <b-col cols="8">
              <b-form-input
                  id="pic"
                  v-model="modal.flag"
                  size="sm"
                  class="text-left"
                  disabled
                  style="background:white"
              ></b-form-input>
          </b-col>
      </b-row>
      <b-row class="mb-1">
          <b-col cols="4">
              <div class="label-entry1">
                  Field Name
              </div>
          </b-col>
          <b-col cols="8">
              <b-form-input
                  id="pic"
                  v-model="modal.field_name"
                  size="sm"
                  class="text-left"
                  disabled
                  style="background:white"
              ></b-form-input>
          </b-col>
      </b-row>
      <b-row class="mb-1">
          <b-col cols="4">
              <div class="label-entry1">
                  Field Value
              </div>
          </b-col>
          <b-col cols="8">
              <b-form-input
                  id="pic"
                  v-model="modal.field_value" 
                  size="sm"
                  class="text-left"
                  disabled
                  style="background:white"
              ></b-form-input>
          </b-col>
      </b-row>
      <b-row class="mb-1">
          <b-col cols="4">
              <div class="label-entry1">
                  QC Message
              </div>
          </b-col>
          <b-col cols="8">
              <b-form-textarea
                  id="pic"
                  v-model="modal.qc_message"
                  size="sm"
                  class="text-left"
                  disabled
                  style="background:white"
                  rows="4"
              ></b-form-textarea>
          </b-col>
      </b-row>
      
			<template #modal-footer>
				<b-button
					size="sm"
					variant="gradient-primary"
					@click="onOk"
				> OK </b-button>
			</template>
			
		</b-modal>
  </div>
</template>

<script>

import {
  BOverlay,
  BTable,
  BAvatar,
  BBadge,
  BPagination,
  BInputGroupAppend,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormInput,
  BInputGroup,
  BFormSelect,
  BButton,
  BButtonGroup,
  BFormDatepicker,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormGroup,
  BFormTextarea,
  BPopover,
  BModal,
  VBModal,
  BForm,
  AlertPlugin,
} from "bootstrap-vue";

import metadata from "@/api/metadata";
import monitoring from "@/api/monitoring";
import moment from "moment";
import rulesetApi from "@/api/ruleset";
import vSelect from "vue-select";
import DinamicTable from "./DinamicTable.vue";
import { VueGoodTable } from "vue-good-table";
export default {
  components: {
    DinamicTable,
    vSelect,
    BOverlay,
    BTable,
    BAvatar,
    BBadge,
    BPagination,
    BInputGroupAppend,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormInput,
    BInputGroup,
    BFormSelect,
    BButton,
    BButtonGroup,
    BFormDatepicker,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormGroup,
    BFormTextarea,
    BPopover,
    BModal,
    VBModal,
    BForm,
    AlertPlugin,
    VueGoodTable
  },
  data() {
    return {
      RESOURCE: "",
      RESOURCE_STORE: [],
      RESOURCE_SELECTED: "",
      DATE_SELECTED: "",
      RAW_DATA_TABLE: [],
      pageOptions: [5, 10, 20, 50],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      filterOn: [],
      fields: [],
      filter_data: "",
      perPage: 10,
      columns: [],
      rows: [],
      modal: {
        element: null,
        station_id: null,
        timestamp: null,
        flag: null,
        field_name: null,
        field_value: null,
        qc_message: null
      }
    };
  },
  mounted() {
    this.getListRuleset();
  },
  watch: {
    RESOURCE(VALUES) {
      this.RESOURCE_SELECTED = VALUES.KEY;
    },
    
  },
  methods: {

    onOk() {
      this.$refs['modal-qc-ev'].hide()
    },

    toCamelCase(str){
      let newStr = "";
      if(str){
        let wordArr = str.split(/[-_]/g);
        for (let i in wordArr){
          newStr += wordArr[i].toUpperCase() + " "
        }
      }else{
        return newStr
      }
      return newStr;
    },

    onCellClick(params) {
      console.log('onCellClick',params)
      let field = params.column.field
      if (field != 'station_id' && field != 'station_name') {
        this.modal = {
          element: this.toCamelCase(params.row[field].element),
          station_id: params.row[field].station_id,
          timestamp: params.row[field].periode+' '+field,
          flag: params.row[field].flag,
          field_name: params.row[field].element,
          field_value: params.row[field].field_value,
          qc_message: params.row[field].qc_message
        }
        this.$refs['modal-qc-ev'].show()
      }
      
    },

    onChange(date, dateString) {
      this.DATE_SELECTED = dateString;
    },

    async getListRuleset() {
      
      try {
        this.isLoading = true
        const { data } = await rulesetApi.listRuleset();
        let rulesets = []
        if (data && data.length > 0) {
          for (var ruleset of data) {
            rulesets.push({ KEY: ruleset.id, LABEL: ruleset.ruleset_name, CODE: ruleset.id })
          }
        }
        this.RESOURCE_STORE = rulesets
        this.isLoading = false
      } catch (e) {
        console.log(e);
      }
    },

    onInitColumns(type, periode=null) {
      console.log('onInitColumns', type, periode)
      this.columns = [
				{
					label: 'Station ID',
					field: 'station_id',
					tdClass: 'text-center',
					thClass: 'text-center theadc'
				},
				{
					label: 'Station Name',
					field: 'station_name',
					tdClass: 'text-left theadstname',
					thClass: 'text-center theadc theadstname'
				}
			]

      if (type == 'harian') {
        for (var i=0; i<24; i++){
          this.columns.push({
            label: this.doubleDigit(i)+':00',
            field: this.doubleDigit(i)+':00',
            tdClass: 'text-center',
            thClass: 'text-center theadc'
          })
        }
      }
      else {
        let year, month, number = null
        if (periode && periode.length == 7) {
          let date = periode.split('-');
          year = date[0]
          month = parseInt(date[1])
          number = this.getDays(year, month)
        }
        console.log('number', number, periode, year, month)
        for (var i=0; i<number; i++){
          this.columns.push({
            label: this.doubleDigit((i+1)),
            field: this.doubleDigit((i+1)),
            tdClass: 'text-center',
            thClass: 'text-center theadc'
          })
        }
      }

    },

    getDays(year, month) {
      return new Date(year, month, 0).getDate();
    },

    doubleDigit(n){
        return n > 9 ? "" + n: "0" + n;
    },
    
    async onFetch() {
      this.onInitColumns();
      // console.log('RESOURCE', this.RESOURCE)
      // console.log('DATE_SELECTED', this.DATE_SELECTED)
      let ruleset = this.RESOURCE.KEY
      let fix = []      
      let typeMonitor = null
      try {
        this.isLoading = true
        const { data } = await rulesetApi.monitoringRuleset(ruleset, this.DATE_SELECTED);
        let result = data;
        let rperiode = null
        for (var arrElement of result) {
          let e = {}
          let station_id, periode, element, qc_type = null
          for (var item of arrElement) {
            if (item.key == 'station_id') {
              station_id = item.value
            }
            if (item.key == 'periode') {
              periode = item.value
              rperiode = item.value
            }
            if (item.key == 'element') {
              element = item.value
            }
            if (item.key == 'qc_type') {
              qc_type = item.value
            }
            
            if (station_id != null && periode != null && element != null && qc_type != null) {
              break;
            }
          }

          for (var item of arrElement) {
            if (item.key == 'station_id' || item.key == 'station_name') {
              e[item.key] = item.value
            }
            else{
              if (item.key == '01') {
                typeMonitor = 'bulanan'
              }
              if (item.key == '00:00') {
                typeMonitor = 'harian'
              }
              e[item.key] = {
                              station_id: station_id,
                              periode: periode,
                              element: element,
                              qc_type: qc_type,
                              flag: item.flag,
                              field_value: item.field_value,
                              qc_message: item.qc_message
                            }
            }         
          }

          fix.push(e)
        } 

        this.onInitColumns(typeMonitor, rperiode);
        this.rows = fix
        console.log('this.rows', this.rows)
        this.isLoading = false
      } catch (e) {
        console.log(e);
      }
      
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>


<style>
  .header-box-custome {
    background: #3f9cff;
    padding: 10px;
    border-radius: 5px;
    color: #ffffff;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 10px;
  }

  .vgt-table thead th.theadc {
		background-color: #f7fafc !important;
	}

  .vgt-table thead td.theadstname {
		width: 100% !important;
	}

  .ant-input {
    height: 37px !important;
  }

</style>



